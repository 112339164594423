import React from 'react';
import LayoutWrapper from 'components/LayoutWrapper';
import { CMSWrapper } from 'configs/globalStyle';
import YourVeterinarians from '../components/YourVeterinarians';

const YourVeterinarianPage = (): FC => {
  return (
    <LayoutWrapper>
      <CMSWrapper>
        <YourVeterinarians />
      </CMSWrapper>
    </LayoutWrapper>
  );
};

export default YourVeterinarianPage;
